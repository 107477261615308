.sliderItemWrapper {
  display: flex;
  align-items: center;
  background-image: url("../../assets/images/sliderImg/bgSlider.svg");
  background-repeat: no-repeat;
  background-size: 60% 100%;
  background-position: top 0 right -9%;
  height: 620px;
  background-color: #fff;

  .sliderItem {
    padding: 100px 30px;
    height: 100%;
    text-align: right;
    margin-left: auto;
    padding-right: 6%;
    .title {
      font-family: "Yekan Bakh";
      font-weight: 800;
      font-size: 34px;
      color: #0e365c;
    }
    .text {
      font-family: "Yekan Bakh";
      font-weight: 400;
      font-size: 18px;
      line-height: 170%;
      color: #292929;
      max-width: 408px;
      text-align: justify;
      direction: rtl;
      margin-left: auto;
      span {
        display: block;
      }
    }
  }

  .imgWrapper {
    position: relative;
    top: 20px;
    flex-basis: 682px;
    border-radius: 39px;
    overflow: hidden;
    max-width: 682px;
    max-height: 450px;
    &::before {
      content: "";
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      background: linear-gradient(
        0deg,
        rgba(50, 179, 224, 0.25),
        rgba(50, 179, 224, 0.25)
      );
      z-index: 2;
      mix-blend-mode: color-burn;
    }

    .imgSlider {
      height: 100%;
      width: 100%;
      position: relative;
      left: 0px;
      top: 0;
      display: block;
      object-fit: cover;
      filter: grayscale(100%);
    }
  }

  @media (max-width: 991px) {
    .sliderItem {
      text-align: center;
      .title {
        font-size: 23px;
      }
      .text {
        text-align: center;
        margin: 0;
        font-size: 16px;
        span {
          display: inline;
        }
      }
    }
  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    height: auto;
    background-size: 100%;
    .imgWrapper {
      flex-basis: auto !important;
    }
    .sliderItem {
      padding: 0;
      margin-left: 0;
      padding-right: 0;
      text-align: center;
      padding-top: 55px;
      .title {
        font-size: 25px;
        line-height: 40px;
        margin: 0;
      }
      .text {
        text-align: center;
        margin: 0;
        font-size: 16px;
      }
    }
  }
}
