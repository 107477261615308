
@import "assets/style/bootstrap-grid.css";
@import "assets/fonts/font/fontiran.css";

html{
  scroll-padding-top:25px;
}

body {
  margin: 0;
  font-family:'Yekan Bakh';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: right;
  font-size: 16px;
 }

*{
  box-sizing: border-box;
}

.app{
  background-image: url("./assets/images/bgHeader.png");
  background-repeat: no-repeat;
  background-position: center top;
  padding-bottom: 30px;
}

@media (min-width: 768px) and (max-width: 1200px) {
  .app{
    background-position: center -40px;
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  html{
    scroll-padding-top:50px;
  }
}

@media (min-width: 768px){
  .hiddenDes{
    display: none !important;
  }
}
 
@media (max-width: 767px){
  html{
    scroll-padding-top:80px;
  }
  .hiddenMobile{
    display: none !important;
  }
}

