@import "../../assets/style/variables.scss";
.tabsWrapper {
  width: 100%;
  .tabsTitleWrapper {
    display: flex;
    justify-content: flex-end;
    padding: 0;
    margin: 0;
    position: relative;
    z-index: 1;
    li {
      list-style: none;
      padding: 16px;

      &.title {
        button {
          background: #eef9fc;
          border-radius: 25px;
          border: none;
          height: 56px;
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 0 20px;
          font-weight: 600;
          font-size: 16px;
          color: #98d9f0;
          font-family: "Yekan Bakh";
          position: relative;
          z-index: 1;
          cursor: pointer;
          transition: all 0.2s ease-in;
          &:hover {
            color: #1c6db8;
          }
        }
      }

      &.active {
        box-sizing: border-box;
        background: #ffffff;
        border: 1px solid #e4f0fb;
        border-bottom: 0;
        border-top-left-radius: 25px;
        border-top-right-radius: 25px;
        position: relative;

        &::before {
          content: "";
          position: absolute;
          bottom: 1px;
          right: 100%;
          width: 30px;
          height: 50px;
          background-color: #fff;
          z-index: 0;
          box-shadow: 7px 7px 0 0 #fff;
          border-bottom: 1px solid #e4f0fb;
          border-right: 1px solid #e4f0fb;
          border-bottom-right-radius: 25px;
        }
        &::after {
          content: "";
          position: absolute;
          bottom: 1px;
          left: 100%;
          width: 30px;
          height: 50px;
          background-color: #fff;
          z-index: 0;
          box-shadow: -7px 7px 0 0 #fff;
          border-bottom: 1px solid #e4f0fb;
          border-left: 1px solid #e4f0fb;
          border-bottom-left-radius: 25px;
        }
        button {
          background: $primary;
          color: #fff;
          white-space: nowrap;
          cursor: pointer;
          &:hover {
            color: #fff;
          }
        }
      }
      &:last-child {
        &::after {
          bottom: -50px;
          left: auto;
          right: -1px;
          box-shadow: none;
          border-bottom: none;
          border-left: none;
          border-bottom-left-radius: 25px;
          border-right: 1px solid #e4f0fb;
        }
      }
    }
  }
  .tabContent {
    background: #ffffff;
    border: 1px solid #e4f0fb;
    border-radius: 25px;
    font-family: "Yekan Bakh";
    font-weight: 400;
    font-size: 18px;
    line-height: 170%;
    color: #292929;
    position: relative;
    top: -2px;
    min-height: 250px;
    padding: 35px 20px;
    width: 100%;
    .address {
      font: normal normal 600 16px/25px "Yekan Bakh";
      display: inline-flex;
      align-items: center;
      color: #32b3e0;
      text-decoration: none;
      justify-content: center;
      background: #eef9fc;
      border-radius: 25px;
      min-width: 337px;
      height: 40px;
      margin-top: 35px;
      transition: all 0.2s ease-in;

      &:hover {
        color: #1c6db8;
      }
    }
  }
  @media (max-width: 767px) {
    .title {
      padding: 15px 10px !important;
      font-size: 15px !important;
      button {
        height: 40px !important;
      }
    }
    .address {
      width: calc(100% - 30px);
      min-width: initial !important;
    }
  }

  @media (max-width: 320px) {
    .title {
      button {
        font-size: 13px !important;
        padding: 0 15px !important;
      }
    }
  }
}
