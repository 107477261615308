.footerWrapper {
  margin-top: 100px;
  .content {
    border-top: 1px solid #e4f0fb;
    .logoWrapper {
      background: white;
      width: 160px;
      margin: -23px auto 0;
      text-align: center;
    }
    .rhMenu {
      margin-top: 85px;
      text-align: center;
    }
    .socialWrapper {
      max-width: 988px;
      height: 88px;
      background: #eef9fc;
      border-radius: 25px;
      margin: 0 auto;
      margin-top: 55px;
      display: flex;
      align-items: center;
      padding: 0 24px;

      .social {
        display: flex;
        align-items: center;
        svg {
          margin-right: 8px;
          cursor: pointer;

          &:hover {
            rect {
              transition: all 0.2s ease-in;
              fill: #21aada;
            }
          }
        }
      }
      .phoneWrapper {
        margin-left: auto;
        display: flex;
        align-items: center;

        label {
          padding-right: 15px;
          font-family: "Yekan Bakh";
          color: #0e365c;
          font-size: 18px;
        }
      }
    }
    .copyWrite {
      font-family: "Yekan Bakh";
      font-size: 12px;
      text-align: center;
      color: #5a5a5a;
      margin: 40px 0 32px;
    }
  }
  @media (max-width: 767px) {
    margin-top: 50px;
    .logoWrapper {
      width: 120px !important;

      .logo {
        max-width: 98px;
      }
    }
    .socialWrapper {
      margin-top: 15px !important;
      height: 72px !important;
      justify-content: center;
    }
  }
}
