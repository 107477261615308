@import "../../assets/style/variables.scss";
.productCard {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 56px;
  position: relative;

  .imgProduct {
    max-width: 478px;
    width: 100%;
    object-fit: cover;
    margin: 0 16px;
  }
  .textWrapper {
    margin: 0 16px;
    .text {
      font-family: "Yekan Bakh";
      font-weight: 400;
      font-size: 18px;
      line-height: 170%;
      direction: rtl;
      text-align: justify;
      color: #292929;
      max-width: 376px;
    }
    .title {
      font-family: "Yekan Bakh";
      font-weight: 800;
      font-size: 34px;
      line-height: 53px;
      color: #0e365c;
    }
  }
  &.productWrapper {
    flex-direction: column;
    .title {
      text-align: center;
    }
    .textWrapper {
      .text {
        text-align: center;
        max-width: 607px;
      }
    }
    .imgProduct {
      max-width: 786px;
      width: 100%;
      margin-top: 5px;
    }
  }
  .linkBtn {
    height: 56px;
    max-width: 191px;
    font-family: "Yekan Bakh";
    font-weight: 600;
    font-size: 16px;
    color: #fff;
    background: $primary;
    border-radius: 25px;
    text-decoration: none;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s ease;
    &:hover {
      background: #21aada;
    }
  }

  .quartercircleSvgPink {
    position: absolute;
    top: 20%;
    left: 4%;
    transform: rotate(165deg);
    width: 20px;
    path {
      fill: #ff8e86;
    }
  }

  .circleSvg {
    position: absolute;
    top: 30%;
    left: 0;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .imgProduct {
      max-width: 300px;
    }
  }

  @media (max-width: 767px) {
    flex-direction: column-reverse;
    &.productWrapper {
      flex-direction: column-reverse !important;
    }
    .textWrapper {
      .title {
        font-size: 26px;
        text-align: center;
        margin: 0;
      }
      .text {
        text-align: center;
        margin: 0;
      }
    }
  }
}
