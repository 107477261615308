@import "../../assets/style/variables.scss";
.rhMenuWrapper {
  .rhMenu {
    direction: rtl;
    a {
      text-decoration: none;
      display: inline-flex;
      margin-right: 34px;
      font-size: 16px;
      color: #5a5a5a;
      font-weight: 600;
      transition: all 0.2s ease-in;
      &:hover {
        color: #1c6db8;
      }
      &.activeClassName {
        color: #1c6db8;
      }
    }
    .contact {
      height: 56px;
      display: inline-flex;
      justify-content: center;
      align-items: center;
      padding: 16px 35px;
      background: $primary;
      border-radius: 25px;
      box-sizing: border-box;
      color: #fff !important;
      &:hover {
        background: #21aada;
      }
    }
  }
  .header {
    display: none;
  }
  @media (max-width: 767px) {
    position: absolute;
    right: 0;
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 70vw;
    background-color: #fff;
    box-shadow: 0px 2px 48px -10px rgba(0, 0, 0, 0.1);
    a {
      padding: 20px;
      display: flex !important;
      margin-right: initial !important;
    }
    .header {
      display: flex;
      align-items: center;
      padding: 30px 20px;
      label {
        margin-left: auto;
        font-family: "Yekan Bakh";
        font-weight: 800;
        font-size: 20px;
        line-height: 31px;
        color: #0e365c;
      }
    }
  }
}
