.modalFullScreenMobile {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  outline: 0;
  background-color: rgba(255, 255, 255, 0.85);
  width: 100vw;
  height: 100vh;
  // height: 100%;
  // height: -webkit-fill-available;
  transition: all 0.3s ease-in-out;
  visibility: hidden;
  opacity: 0;

  &.avoidEvents {
    pointer-events: none;
  }

  &.overlay {
    background-color: rgba(0, 0, 0, 0.8);
  }

  &.showModal {
    visibility: visible;
    opacity: 1;
  }
}

.rhOverflow {
  overflow: hidden;
}
