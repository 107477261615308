.topHeader {
  padding-top: 15px;
  margin-bottom: 45px;
  padding-bottom: 10px;
  position: sticky;
  top: 0;
  left: 0;
  transition: all 0.1s ease-in;

  .menuWrapper {
    display: flex;
    align-items: center;
    .logo {
      margin-left: auto;
      transition: all 0.1s ease-in;
    }
  }
  &.sticky {
    z-index: 10;
    background-color: #fff;
    box-shadow: 0px 0px 16px rgba(102, 102, 102, 0.08);
  }
}
.headerWrapper {
  .headerImgWrapper {
    .imgWrapper {
      position: relative;
      img {
        width: 100%;
        max-width: 683px;
        max-height: 512px;
      }
      .quartercircleSvgPink {
        position: absolute;
        top: 20%;
        left: 0;
        transform: rotate(165deg);
        width: 20px;
        path {
          fill: #ff8e86;
        }
      }
    }

    .colRight {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      justify-content: center;
      height: 100%;
      position: relative;
      .circleSvg {
        position: absolute;
        top: 5%;
        left: 0;
      }
      .quartercircleSvg {
        position: absolute;
        bottom: 20%;
        right: 5%;
      }
      .quartercircleSvgYellow {
        transform: rotate(-96deg);
        width: 20px;
        path {
          fill: #ffd25d;
        }
      }
      .circleSvgPink {
        ellipse {
          fill: #ff8e86;
        }
      }
      h5 {
        font-weight: 600;
        font-size: 22px;
        line-height: 34px;
        color: #187c9f;
        margin: 0;
      }
      h1 {
        font-size: 4rem;
        line-height: 99px;
        color: #0e365c;
        display: block;
        font-weight: 950;
        margin: 0;
        white-space: nowrap;
      }
      p {
        font-size: 20px;
        line-height: 170%;
        color: #292929;
        text-align: right;
        max-width: 450px;
        direction: rtl;
      }
      .btnWrapper {
        max-width: 510px;
        align-self: flex-start;
        display: flex;
        .rhLinkbutton {
          margin: 25% 0 0 16px;
          white-space: nowrap;
        }
        .assistBtn {
          background: none;
          &:hover {
            color: #1c6db8;
            svg {
              path {
                fill: #1c6db8;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 991px) {
  .headerImgWrapper {
    justify-content: center !important;

    .row {
      flex-direction: column-reverse;
    }

    .quartercircleSvgYellow {
      position: absolute;
      right: 0;
      top: 0;
    }
  }
}

@media (min-width: 768px) and (max-width: 1200px) {
  .colRight {
    h1 {
      font-size: 50px !important;
    }
  }
}

@media (min-width: 768px) and (max-width: 991px) {
  .rhMenuWrapper {
    .rhMenu {
      a {
        margin-right: 14px !important;
      }
      .contact {
        height: 46px !important;
        padding: 16px 25px !important;
      }
    }
  }

  .colRight {
    align-items: center !important;
    .btnWrapper {
      margin: 0 auto;
      .rhLinkbutton {
        margin: 5% 0 0 0 !important;
        white-space: nowrap;
      }
    }
  }
}
@media (max-width: 767px) {
  .topHeader {
    max-width: 800px;
    margin-bottom: 32px;
  }
  .headerImgWrapper {
    .quartercircleSvgPink {
      top: 150% !important;
    }
  }
  .logo {
    margin: 0 auto;
    img {
      max-width: 98px;
    }
  }
  .menuWrapper {
    padding-top: 16px;
  }
  .colRight {
    align-items: center !important;
    h1 {
      font-size: 40px !important;
      line-height: initial !important;
    }
    p {
      font-size: 16px !important;
      text-align: center;
    }
    .btnWrapper {
      align-self: center !important;
      .rhLinkbutton {
        margin: 20px 2px !important;
      }
    }

    .quartercircleSvg {
      bottom: -18% !important;
      left: 5%;
      transform: rotate(91deg);
    }
  }
}
