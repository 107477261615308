.rhLinkbutton {
  font-family: "Yekan Bakh";
  font-weight: 600;
  font-size: 16px;
  color: #0e365c;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 0 26px;
  height: 56px;
  background: #f1f7fd;
  border-radius: 25px;
  text-decoration: none;
  transition: all 0.2s ease-in;
  cursor: pointer;
  &:hover {
    background: #e4f0fb;
    color: #0e365c;
    svg {
      path {
        fill: #0e365c;
      }
    }
  }
  svg {
    margin-right: 16px;
  }
  @media (max-width: 767px) {
    padding: 0 10px;
    svg {
      margin-right: 5px;
    }
  }
}
