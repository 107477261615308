@import "../../assets/style/variables.scss";

.homePage {
  .servicesWrapper {
    position: relative;
    overflow: hidden;
    padding: 50px 0 60px;
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 50px;
      left: 0;
      background: linear-gradient(
        155.23deg,
        rgba(228, 240, 251, 0) 40.53%,
        #f1f7fd 103.24%
      );

      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 120%;
      height: 16%;
      bottom: -50px;
      right: -150px;
      background: #fff;
      z-index: -1;
      transform: rotate(-5deg);
    }

    > div > div:nth-child(2n-1):not(:last-child) {
      flex-direction: row-reverse;
      .quartercircleSvgPink {
        top: 40% !important;
        right: 4% !important;
        left: auto !important;
        transform: rotate(0deg) !important;
        path {
          fill: #ffd25d;
        }
      }
      .circleSvg {
        top: 30% !important;
        left: auto !important;
        right: 0 !important;
      }
    }
    .servicesTitle {
      bottom: 13px;
    }

    .globeSvg {
      position: absolute;
      bottom: 43%;
      left: 10%;
    }
  }
  .logoCompanyWrapper {
    padding-top: 50px;
    padding-bottom: 154px;
    position: relative;
    overflow: hidden;
    h2 {
      margin-bottom: 0;
    }
    h6 {
      font-family: "Yekan Bakh";
      font-weight: 400;
      font-size: 18px;
      color: #292929;
      margin: 0 0 60px;
      text-align: center;
    }
    .logoWrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .imgLogo {
        width: 61px;
        height: 61px;
      }
    }
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 100%;
      bottom: 50px;
      left: 0;
      background: linear-gradient(
        179deg,
        rgba(228, 240, 251, 0) 39.34%,
        #f1f7fd 100.22%
      );
      z-index: -1;
    }
    &::after {
      content: "";
      position: absolute;
      width: 120%;
      height: 15%;
      bottom: 20px;
      left: -10%;
      z-index: -1;
      transform: rotate(1.5deg);
      background-color: #fff;
    }
    .logoCompanyTitle {
      span {
        left: calc(50% - 90px);
        width: 209px;
      }
    }
  }
  .slideShowWrapper {
    padding: 0;
    .organizationaSlider {
      .swiper-pagination {
        bottom: 20%;
        right: 15%;
        left: auto;
        max-width: 20%;
        display: flex;
        justify-content: center;
        .swiper-pagination-bullet {
          width: 12px;
          height: 4px;
          background: #98d9f0;
          border-radius: 112px;
          box-shadow: none;
          margin: 0 5px;

          &.swiper-pagination-bullet-active {
            background: #1c6db8;
          }
          &:hover {
            background: #1c6db8;
          }
        }
      }
      .swiper-button-next,
      .swiper-button-prev {
        right: 21%;
        width: 43px;
        height: 24px;
        left: auto;
        top: auto;
        bottom: 30%;
        background: #21aada;
        border-radius: 52px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &::after {
          content: "";
          font-family: none;
          width: 18px;
          height: 13px;
          border: 0;
          background: url(../../assets/images/Icons/arrowR.svg);
        }
        &:hover {
          background: #187c9f;
        }
      }

      .swiper-button-prev {
        right: 26%;
        transform: rotate(-180deg);
      }
    }
  }
  .team {
    padding-top: 100px;
    .seeJobWrapper {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        top: 7%;
        left: 0px;
        width: 100%;
        height: 135px;
        background-image: url("../../assets/images/seeJobBg.png");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: left top;
        z-index: -1;
      }

      &::after {
        content: "";
        position: absolute;
        bottom: 100px;
        left: 0px;
        width: 100%;
        height: calc(100% - 245px);
        background-color: #f1f7fd;
        z-index: -2;
      }
      .title {
        margin-bottom: 0;
      }
      .text {
        max-width: 542px;
      }
    }
  }
  .contactWrapper {
    padding-top: 55px;
    img {
      width: 100%;
    }
    .tabContent {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .place {
        text-align: center;
      }
    }
    .addressWrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-end;
      height: 100%;
      .phone {
        margin: 0;
        label {
          margin: 0 30px 30px;
          min-width: 60px;
        }
      }
    }
    .contactTitle {
      span {
        left: calc(50% - 80px);
        width: 180px;
      }
    }
  }

  @media (max-width: 1399px) {
    .sliderItemWrapper {
      background-position: top right;
      .sliderItem {
        .text {
          font-size: 16px;
        }
      }
    }
    .globeSvg {
      bottom: 43%;
      left: auto !important;
      right: 0 !important;
    }
  }

  @media (min-width: 992px) and (max-width: 1200px) {
    .slideShowWrapper {
      padding: 0;
      .organizationaSlider {
        .swiper-button-prev {
          right: 27%;
        }
        .swiper-pagination {
          right: 16%;
        }
      }
    }
    .team {
      padding-top: 30px;
      scroll-margin-top: 40px;
    }
  }

  @media (min-width: 768px) and (max-width: 1200px) {
    .servicesWrapper {
      padding: 50px 0 0;
    }
    .team {
      .seeJobWrapper {
        &::after {
          height: calc(100% - 197px);
        }
        .productCard {
          min-height: 400px;
          .textWrapper {
            padding-bottom: 35px;
            .text {
              margin: 0 0 10px;
            }
            .linkBtn {
              height: 40px;
            }
          }
        }
      }
    }
    .logoCompanyWrapper {
      padding-top: 50px;
    }
    .contactWrapper {
      padding-top: 0;
      scroll-margin-top: 50px;
    }
  }
  @media (max-width: 991px) {
    .team {
      padding-top: 0;
    }
    .contactWrapper {
      .row {
        flex-direction: column-reverse;
      }
      .rhTitle {
        margin: 0 auto 20px !important;
      }
    }
  }
  @media (min-width: 768px) and (max-width: 991px) {
    .rhTitle {
      margin: 0 !important;
    }
    .logoCompanyWrapper {
      .logoWrapper {
        justify-content: center !important;
        flex-wrap: wrap;
        .imgLogo {
          margin: 10px;
        }
      }
    }
    .slideShowWrapper {
      padding: 0;
      .sliderItemWrapper {
        height: 500px;
        .title {
          margin: 5px 0 0 0;
        }
        .text {
          margin: 0;
        }
      }
      .organizationaSlider {
        .swiper-button-next,
        .swiper-button-prev {
          bottom: 26%;
        }
        .swiper-button-prev {
          right: 30%;
        }
        .swiper-pagination {
          right: 18%;
        }
      }
    }
    .team {
      scroll-margin-top: 20px;
    }
  }

  @media (max-width: 767px) {
    .rhTitle {
      font-size: 28px;
    }
    .servicesWrapper {
      padding: 50px 0 0;
      > div > div:nth-child(2n-1):not(:last-child) {
        flex-direction: column-reverse;
      }
      &::after {
        right: -30px !important;
      }
      .servicesTitle {
        span {
          left: calc(50% - 97px);
          bottom: 13px;
          width: 187px;
        }
      }
    }
    .logoWrapper {
      flex-wrap: wrap;
      justify-content: center !important;
      .imgLogo {
        margin: 0 6px 24px;
      }
    }
    .team {
      padding-top: 20px;
      scroll-margin-top: 20px;
      .seeJobWrapper {
        &::before {
          top: -30px !important;
        }
        &::after {
          height: calc(100% - 100px) !important;
        }
        .productCard {
          flex-direction: column;
          .linkBtn {
            margin: 10px auto !important;
          }
        }
      }
    }
    .contactWrapper {
      padding-top: 0;
      scroll-margin-top: 10px;
      .row {
        flex-direction: column-reverse;
      }
      .contactTitle {
        span {
          left: calc(50% - 55px);
          width: 110px;
        }
      }
    }
    .logoCompanyWrapper {
      padding: 0 0 50px !important;
      .logoCompanyTitle {
        span {
          left: calc(50% - 70px);
          width: 140px;
        }
      }
      h6 {
        margin-bottom: 10px;
      }
    }

    .slideShowWrapper {
      position: relative;
      &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
      }
      padding: 0 0 70px;
      .organizationalTitle {
        span {
          left: calc(50% - 60px);
          width: 150px;
        }
      }
      .organizationaSlider {
        padding-bottom: 30px;
        .swiper-button-next,
        .swiper-button-prev {
          bottom: 25% !important;
        }
        .swiper-button-next {
          right: 0;
        }
        .swiper-button-prev {
          left: 0;
        }
        .swiper-pagination {
          max-width: 100% !important;
          left: 0;
          bottom: 0 !important;
        }
      }
      .text {
        max-width: 300px !important;
      }
    }
  }
}
