@import "../../assets/style/variables.scss";
.rhTitle {
  font-family: "Yekan Bakh";
  font-weight: 800;
  font-size: 42px;
  line-height: 65px;
  text-align: center;
  color: #0e365c;
  text-shadow: -2px 2px 0 #fff;
  position: relative;

  .titleBg {
    position: absolute;
    width: 207px;
    height: 16px;
    left: calc(50% - 57px);
    bottom: 10px;
    background: $primary;
    border-radius: 0px 0px 4555px 5px;
    z-index: -1;
  }

  @media (max-width: 767px) {
    margin: 0 auto;
  }
}
