/**
*
*	Name:			YekanBakh Fonts
*	Version:			1.0
*	Author:			Reza Bakhtiarifard (rbakhtiarifard.ir)
*	Created on:		May 2022
*	Updated on:		May 2022
*	Vendor:			http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونت پیکان یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
YekanBakh fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com 
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (.....)
------------------------------------------------------------------------------------- fonts/-
*	
**/


@font-face {
	font-family: Yekan Bakh;
	font-style: normal;
	font-weight: 300;
	src: url('./YekanBakh-Light.woff') format('woff'),   
	url('./YekanBakh-Light.woff2') format('woff2');	
}

@font-face {
	font-family: Yekan Bakh;
	font-style: normal;
	font-weight: normal;
	src: url('./YekanBakh-Regular.woff') format('woff'),   
	url('./YekanBakh-Regular.woff2') format('woff2');		 
}

@font-face {
	font-family: Yekan Bakh;
	font-style: normal;
	font-weight: 600;
	src: url('./YekanBakh-SemiBold.woff') format('woff'),   
	url('./YekanBakh-SemiBold.woff2') format('woff2');		 
}

@font-face {
	font-family: Yekan Bakh;
	font-style: normal;
	font-weight: bold;
	src: url('./YekanBakh-Bold.woff') format('woff'),   
	url('./YekanBakh-Bold.woff2') format('woff2'); 
}

@font-face {
	font-family: Yekan Bakh;
	font-style: normal;
	font-weight: 800;
	src: url('./YekanBakh-ExtraBold.woff') format('woff'),   
	url('./YekanBakh-ExtraBold.woff2') format('woff2');		 
}

@font-face {
	font-family: Yekan Bakh;
	font-style: normal;
	font-weight: 950;
	src: url('./YekanBakh-ExtraBlack.woff') format('woff'),   
	url('./YekanBakh-ExtraBlack.woff2') format('woff2');		 
}